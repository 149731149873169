/*
 * @Author: your name
 * @Date: 2021-07-05 16:08:45
 * @LastEditTime: 2021-07-05 16:08:58
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \web\src\pages\mixin\dateRangePicker.js
 */
let DateRangePickerMixin = {
  data () {
    return {
      JZFormRules: {
        beginTime: [{trigger: ['blur', 'change'], validator: this.validateBeginTime}],
        endTime: [{trigger: ['blur', 'change'], validator: this.validateEndTime}],
      },
      districtTree: [],
      allShopPerportyLists: [],
    };
  },
  methods: {
    // 操作时间(起)验证
    validateBeginTime (rule, value, callback) {
      this.$refs.JZForm.clearValidate();
      // 如果操作时间(止)没选，cb
      if (!this.JZForm.endTime || !value) {
        callback();
      } else {
        // 操作时间(止)有，进行判断
        if (this.compareDate(value, this.JZForm.endTime)) {
          // 如果起始在结束之前
          callback();
        } else {
          callback(new Error('操作时间(起)不可大于操作时间(止)，请重新选择'));
        }
      }
    },
    validateEndTime (rule, value, callback) {
      this.$refs.JZForm.clearValidate();
      if (!this.JZForm.beginTime || !value) {
        callback();
      } else {
        // 操作时间(起)有
        if (this.compareDate(this.JZForm.beginTime, value)) {
          callback();
        } else {
          callback(new Error('操作时间(止)不能小于操作时间(起)，请重新选择'));
        }
      }
    },
    compareDate (start, end) {
      return new Date(end).getTime() >= new Date(start).getTime();
    },

  },
};
export default DateRangePickerMixin;
